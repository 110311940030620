import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Proyectos = () => (
  <Layout noNav={true}>
    <SEO title="Proyectos" />
    <header>
      <div className="masthead bg-proy">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 text-center">
              <h1 className="font-weight-black">Lista de Proyectos</h1>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="container pt-5">
      <div class="row">
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2020</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para las oficinas de la Dirección de
                Certificación de Licencias de la AFAC Aeropuertos y Servicios
                Auxiliares.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones y Seguridad para el centro de salud UNEME
                Capa el Oasis CDMX Fundación Caros Slim.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para la remodelación del piso 1 y 2 edificio
                Torres Adalid, oficinas Medica Azul Grupo la Cruz Azul.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para unidad mi Bodega Aurrera Juventino
                Rosas. <small> Guanajuato</small>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2019</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la remodelación del edificio Terminal
                Existente Aeropuerto de Chetumal.
                <small> Chetumal, Quintana Roo</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de Detección de
                Incendios para la Ampliación en la terminal dos para las
                posiciones de contacto en dedo “L” Aeropuerto Internacional de
                la Ciudad de México.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la nueva Torre de Control Aeropuerto de
                Chetumal.
                <small> Chetumal, Quintana Roo</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la ampliación del Edificio Terminal
                existente Aeropuerto de Chetumal.
                <small> Chetumal, Quintana Roo</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la ampliación del Edificio Terminal
                Aeropuerto de Puerto Escondido O.
                <small> Puerto Escondido, Oaxaca</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de
                Telecomunicaciones para la adecuación de sala de llegada
                Internacional Aeropuerto de Loreto.
                <small> Baja California Sur</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones y Seguridad para el Centro de Salud Lomas de
                la Era CDMX Fundación Caros Slim.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones y Seguridad para remodelación de edificios 5,
                6 y 7 para oficinas de la Secretaria de Salud Instituto Nacional
                de Geriatría.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones y Seguridad para el centro de salud Santa
                Rosa Xochiac CDMX Fundación Caros Slim.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Elaboración de catálogos de
                conceptos de instalaciones de Telecomunicaciones para licitación
                de construcción de nueva torre Médica Hospital HMG Coyoacán.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Anteproyecto de instalaciones
                de Telecomunicaciones para la ampliación del centro de
                capacitación CIIASA Aeropuertos y Servicios Auxiliares.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Anteproyecto de instalaciones
                de Telecomunicaciones para la ampliación y remodelación de
                oficinas en Estación de Combustibles Aeropuerto Internacional de
                Puerto Escondido.
                <small> Puerto Escondido, Oaxaca</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Supervisión de Obra de
                Instalaciones de Telecomunicaciones para la Obra de Aulas
                Corporativas en Corporativo Tlalpan, en Gran Sur Grupo la Cruz
                Azul.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para la remodelación del piso 6 y 7 edificio
                Torres Adalid, oficinas Concretos Cruz Azul Grupo la Cruz Azul.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Supervisión de Obra de
                Instalaciones de CCTV, Detección de Incendios y BMS, para la
                actualización tecnológica de estos sistemas en Corporativo
                Tlalpan en Gran Sur Grupo la Cruz Azul.
                <small> Ciudad de México</small>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2018</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones y Seguridad Clínica Oftalmológica Toluca.
                <small> Estado de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones y Seguridad para la ampliación y remodelación
                área de Diálisis Centro Médico Ecatepec ISSEMyM.
                <small> Estado de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones y Seguridad para para la ampliación y
                remodelación de los servicios auxiliares de Tratamiento,
                Hospitalización, Gobierno, Enseñanza Hospital General ISSSTE 71.
                <small> Ciudad Juárez, Chihuahua</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Elaboración de catálogo de
                conceptos a base de generadores tipo obra para la supervisión
                externa de la construcción de la Unidad IMSS Unidad de Medicina
                Familiar 10+5 Consultorios.
                <small> León, Guanajuato</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones y Seguridad Clínica de Consulta Externa "A"
                Nezahualcóyotl ISSEMYM.
                <small> Estado de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones y Automatización para remodelación de área de
                Urgencias Hospital Gea González.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Hospital General 120 camas
                Nuevo Laredo Tamaulipas. Proyecto de Instalaciones de
                Telecomunicaciones Seguridad y Automatización para el nuevo
                Hospital General Nuevo Laredo Tamaulipas.
                <small> Nuevo Laredo, Tamaulipas</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de
                Telecomunicaciones Para la adecuación de edificio de caja única
                para servicio a la Aviación General, ampliación de un segundo
                nivel para Autoridades Aeropuerto de Ciudad Obregón.
                <small> Ciudad Obregón, Sonora</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de
                Telecomunicaciones para la ampliación del cuarto de máquinas o
                subestación eléctrica Aeropuerto de Uruapan.
                <small> Uruapan, Michoacán</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Anteproyecto de instalaciones
                de Telecomunicaciones para la ampliación del centro de
                capacitación CIIASA Aeropuertos y Servicios Auxiliares.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Anteproyecto de instalaciones
                de Telecomunicaciones para la ampliación y remodelación de
                oficinas en Estación de Combustibles Aeropuerto Internacional de
                Puerto Escondido.
                <small> Puerto Escondido, Oaxaca</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de actualización
                tecnológica del Circuito Cerrado de Televisión del Corporativo
                Tlalpan en Gran Sur Grupo la Cruz Azul.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de actualización
                tecnológica del sistema de Detección de Incendios del
                Corporativo Tlalpan en Gran Sur Grupo la Cruz Azul.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Levantamiento de estado
                actual de las instalaciones de Circuito Cerrado de Televisión y
                Detección de Humos del Edificio Corporativo Tlalpan Gran Sur
                Grupo la Cruz Azul.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de Instalaciones de
                Telecomunicaciones para las aulas corporativas en Corporativo
                Tlalpan Gran Sur Grupo la Cruz Azul.
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de Instalaciones de
                Telecomunicaciones para el edificio de oficinas 15 niveles de la
                nueva sede Centros de Integración Juvenil de la Secretaría de
                Salud del Gobierno Federal.
                <small> Ciudad de México</small>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2017</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Actualización de proyecto de
                instalaciones de Telecomunicaciones para el BOTACO Aeropuerto
                Internacional de Ciudad Victoria.
                <small> Ciudad Victoria, Tamaulipas</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de Instalaciones de
                Telecomunicaciones para la reubicación y reintegración del
                Centro de Monitoreo de CCTV Aeropuerto Internacional de Toluca.
                <small> Estado de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de Instalaciones de
                Telecomunicaciones para la Ampliación de Aeropasillos Aeropuerto
                Internacional de Toluca.
                <small> Estado de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para Hospital Especialidades.{" "}
                <small> Mexicali, Baja California.</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Automatización y Control Hospital 250 camas.{" "}
                <small> León, Guanajuato</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para Centro de Saludo Tlalpan.{" "}
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Automatización y Control Tercera etapa ampliación de Hospital
                Naval. <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones ampliación y remodelación en Hospital de alta
                especialidad (HAE114) "R. M. Cárdenas de la Vega.{" "}
                <small> Culiacán, Sinaloa</small>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2016</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para Moscamed SENASICA{" "}
                <small> Tapachula, Chiapas.</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la reestructuración del Edifico “G “ UAM
                Azcapotzalco D.F. <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Supervisión de instalaciones
                de Telecomunicaciones en obra de remodelación Edificio “V” UAM
                Xochimilco. <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para los laboratorios de investigación
                metalúrgica minera Frisco Grupo Carso.{" "}
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos para las oficinas de despacho e información de vuelo
                (ODIV) y remodelación de la estación meteorológica en el
                Aeropuerto. <small> Ixtepec, Oaxaca</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Automatización y Control segunda etapa ampliación de Hospital
                Naval. <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la ampliación y remodelación del
                servicio de urgencias, toxicología, endoscopias y la unidad de
                quimioterapia y aféresis del Hospital La Raza .{" "}
                <small> Ciudad de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                telecomunicaciones remodelación del servicio de anatomía
                patológica UMAE HE No. 25 ISSSTE.{" "}
                <small> Monterrey, N.L.</small>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2015</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos, Circuito Cerrado de Televisión, Detección de
                Incendios, Audio y Control de Accesos para remodelación y
                ampliación del Aeropuerto <small>Colima</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos, Circuito Cerrado de Televisión, Detección de
                Incendios, para el SEI Aeropuerto <small>Puebla</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos, Circuito Cerrado de Televisión, oficinas en
                delegación Iztapalapa.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Dictamen técnico para
                ampliación sala 75 red de Fibras Ópticas terminal 2 del AICM.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para el Centro de innovación y competitividad
                en energías renovables y medio ambiente. <small>Durango</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para la remodelación del mercado principal de
                la Delegación Iztapalapa.<small>México, DF</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Actualización Proyectos de
                instalaciones de Telecomunicaciones para la Defensoría de los
                Derechos Públicos.<small> Estado de Morelos</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para la remodelación y ampliación en el
                Hospital del ISSSTE de alta especialidad.
                <small> Mérida Yucatán.</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para la rehabilitación del edificio de
                pasajeros en el aeropuerto de Ixtepec.<small> Oaxaca</small>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInRight">
          <h3 class="column-title">2014</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Voz y Datos, Televisión para el Centro Avanzado en
                Radioterapia. <small>Baja California Norte 2013-</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de enlaces externos
                instalaciones de Telecomunicaciones para la readecuación de
                espacios del Aeropuerto. <small>Chetumal 2013-</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la Aduana de Aeropuerto{" "}
                <small>Nuevo Laredo 2013-</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Voz y Datos, Televisión para el Centro Avanzado en
                Radioterapia. <small>México DF.</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para los Almacenes de Jugos Cítricos de
                México, SAPI de C. V. <small>Tuxpan, Veracruz</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para el Hospital de Traumatología y
                ortopedia. <small>Puebla</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para el Hospital de comunitario en Xicotepec.{" "}
                <small>Puebla</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para remodelación en el Aeropuerto.{" "}
                <small>Chetumal</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para el nuevo recinto fiscal en Aeropuerto.{" "}
                <small>Nuevo Laredo</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para remodelación en el Aeropuerto.{" "}
                <small>Posa Rica, Veracruz</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Telecomunicaciones para las nuevas oficinas de combustibles en
                el Aeropuerto. <small>Querétaro</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Levantamiento de estado
                actual de la red de voz y Datos del Centro Medico Siglo XXI.{" "}
                <small>México DF.</small>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2013</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos, Circuito Cerrado de Televisión y Detección de
                Incendios para el edificio de Talleres Aulas y Oficinas de la
                Carrera de Diseño Industrial UAM Unidad Xochimilco.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto para la adecuación
                de red de fibra óptica en estacionamientos para el Circuito
                Cerrado de Televisión UAM Unidad Iztapalapa.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Radio C., Voz y Datos, Televisión Fomento a la salud para
                el Hospital general 250 camas <small>Morelia</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Voz y Datos, Televisión para el Hospital Centro
                Oncológico Internacional<small> Tijuana, BCN</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Voz y Datos, Televisión para el Hospital Centro
                Oncológico Internacional Canacintra<small> México DF.</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                CCTV, Voz y Datos Enlace de fibra óptica para La ampliación del
                SEI Aeropuerto <small>Puerto Escondido, Oaxaca</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Voz y Datos, Televisión para el Centro de Rehabilitación
                Integral Hospital General de México.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                CCTV, Voz y Datos, control de Accesos y Detección de Incendios,
                y Enlace de fibra óptica para el almacén de medicamentos de
                Sanofi Pasteur.<small> Toluca, Estado de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para las Oficinas Generales de PENSIONISSSTE.
                México DF.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la Defensoría de los Derechos Públicos.{" "}
                <small>Morelos 2013-</small>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInRight">
          <h3 class="column-title">2012</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos, Circuito Cerrado de Televisión y Detección de
                Incendios para el edificio de Ciencias Básicas e Ingeniería UAM
                Unidad Azcapotzalco.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Radio C., Voz y Datos, Televisión Fomento a la salud para
                el Hospital General.<small> Yajalon Chiapas</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de infraestructura
                de planta externa para telecomunicaciones Nuevo aeropuerto de
                Palenque. <small>Chiapas</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Radio C., Voz y Datos, Televisión Fomento a la salud para
                el Hospital de la Mujer. <small>Tehuacán, Puebla</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Radio C., Voz y Datos, Televisión Fomento a la salud para
                el Hospital Tecamachalco<small> Puebla</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Radio C., Voz y Datos, Televisión Fomento a la salud para
                el Hospital General 30C <small>Ciudad Constitución, BCS</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, CCTV, Control de Accesos Detección de Incendios
                para los Laboratorios de Calidad de Combustibles ASA.
                <small>Morelia</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, CCTV, Control de Accesos Detección de Incendios
                para Centro comercial y Hotel en Tula. <small>Hidalgo</small>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2011</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Apoyo en desarrollo de los
                Proyectos de instalaciones de Voz y Datos, Circuito Cerrado de
                Televisión, Detección de Incendios y Control de Accesos para el
                edificio de Ciencia y Tecnología UAM Unidad Iztapalapa.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de sistema de
                Circuito Cerrado de Televisión para el parque recreativo
                Cuitláhuac del Iztapalapa.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos, control de Accesos, CCTV, y Detección de incendios,
                edificio nuevo de la PGJ <small>Cuernavaca, Morelos</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para el nuevo Hospital General de Zapopan.
                2010-{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Radio C., Voz y Datos, Televisión Fomento a la salud para
                el nuevo Hospital de Tecamachalco<small> Puebla</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Radio C., Voz y Datos, Televisión Fomento a la salud para
                el nuevo Centro de Rehabilitación estatal de Campeche.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio,., Voz y Datos, Televisión Fomento a la salud para la
                Clínica Geriátrica. <small> Estado de México</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio,., Voz y Datos, Televisión Fomento a la salud para la
                Clínica CEAPS cuatro núcleos. <small> Estado de México</small>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2010</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para el edificio del Archivo Histórico del
                Estado de Tabasco.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la remodelación de área de Cirugías del
                Instituto Nacional de Cardiología.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Detección de Incendios cede de PGR Distrito Federal.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Readecuación de proyectos de
                instalaciones de Voz Datos y Seguridad, para edificio de
                Tribunal Superior de Justicia del Distrito Federal.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Audio, Voz y Datos, Televisión Fomento a la salud del nuevo
                Hospital General. <small>Zapopan</small>
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la ampliación y remodelación de la
                Clínica Hospital del ISEM Tultitlan. 2010-
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones exteriores centro de reclusión en Veracruz.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones unidad académica departamental nivel 4
                INIFED.
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2009</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyectos de Detección de
                Incendios, Voz y Datos, CCTV y Control de Accesos para la
                remodelación del piso 3 al 18 del edificio ubicado en Reforma
                364 para el Centro Nacional de Evaluación y Control de Confianza
                de la SSP.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos para la Casa de la Cultura de Pachuca Hidalgo.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos y CCTV para edificio de Juzgados del G.D.F.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                CCTV, Detección de Incendios, y Voz y Datos para los 4 edificios
                de Gobierno de Bachilleres Veracruz.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, Detección de Incendios y Circuito Cerrado de
                Televisión Para el Canal Judicial de la Suprema Corte de
                Justicia de la Nación.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, para la construcción del primer piso del área de
                radioneurocirugía del Instituto Nacional de Neurología.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para Estancia de Bienestar y Desarrollo
                Infantil No 78 Ciudad Obregón.
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2008</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                audio, Voz y Datos y Televisión Fomento a la Salud de la Clínica
                del Sida de la Secretaria de Salud del GDF.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de Detección de
                Incendios para la remodelación del piso 3 al 11 del edificio
                ubicado en Reforma 364 para el Centro Nacional de Evaluación y
                Control de Confianza de la SSP.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones
                Comunicaciones para la remodelación del primer segundo y tercer
                nivel de la torre dos de Humanidades UNAM.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos, Audio, Televisión Fomento a la Salud, y
                Radiocomunicación para el Hospital de Especialidades En Soledad
                San Luis Potosí.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos, Audio, Televisión Fomento a la Salud, y
                radiocomunicación para el Hospital General de Comitán Chiapas.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos, de remodelación de oficinas órgano interno de
                control ASA.
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2007</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Estudios de factibilidad para
                la actualización de las instalaciones de telecomunicaciones del
                Edificio Terminal del Aeropuerto de Baja California sur.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Estudios de factibilidad para
                la actualización de las instalaciones de telecomunicaciones del
                Edificio Terminal del Aeropuerto de Colima.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Estudios de factibilidad para
                la actualización de las instalaciones de telecomunicaciones del
                Edificio Terminal del Aeropuerto de Campeche.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de Detección de
                Incendios para la remodelación de la planta baja primer y
                segundo nivel del edificio del centro Nacional de Evaluación de
                confianza reforma 364 de la SSP.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, Detección de Incendios, Control de Accesos y
                Circuito Cerrado de Televisión para la Estación de Seguridad de
                la SSP en Saltillo Coahuila.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, Audio (voceo) y Paciente enfermera Para la
                remodelación de unidad de encamados para cirugía plástica y
                reconstrucción del Hospital Rubén Leñero.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, Detección de Incendios, Control de Accesos y
                Circuito Cerrado de Televisión para la Estación de Seguridad de
                la SSP en San Juan Ixhuatepec.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, Detección de Incendios, Control de Accesos y
                Circuito Cerrado de Televisión para la Estación de alta
                Seguridad de la SSP en Teziutlán Puebla.{" "}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2006</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para el Cuartel General del Aeropuerto de
                Tuxtla Gutiérrez Chiapas.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto de Instalaciones de
                Voz y Datos para el Edificio de la Asamblea Legislativa del
                G.D.F.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la comandancia local y regional del
                Aeropuerto de Tuxtla Gutiérrez Chiapas.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para oficinas en piso 10 de WTC México DF.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyecto sistema de detección
                de incendios para oficinas de la PGR. México DF.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones para la comandancia local y regional del
                Aeropuerto de Tuxtla Gutiérrez Chiapas.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Telecomunicaciones centro de datos SSP.
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2005</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, detección de incendios, audio (voceo) y Circuito
                Cerrado de Televisión para la ampliación provisional de la sala
                de llegadas del Aeropuerto de Loreto Baja California Sur.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, detección de incendios, audio (voceo) y circuito
                cerrado de televisión para la remodelación y ampliación de la
                planta alta del Edificio Terminal del Aeropuerto de Nuevo
                Laredo.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos, detección de incendios, audio y circuito cerrado
                casa en Tlalpan México DF.{" "}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 wow fadeInLeft">
          <h3 class="column-title">2004</h3>
          <p></p>
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <i class="fa fa-check-square"></i> Proyecto de instalaciones de
                Voz y Datos para la reestructuración de la red de datos de cada
                uno de los edificios de la Facultad de Química. UNAM
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                Voz y Datos para la remodelación de la Casa del Lago de la UNAM.{" "}
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                telecomunicaciones para la remodelación de los espacios de las
                oficinas generales de ASA.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Proyectos de instalaciones de
                telecomunicaciones para el parque recreativo Gomes Farías México
                DF.
              </li>
              <li>
                <i class="fa fa-check-square"></i> Revisión y actualización de
                proyectos de instalaciones de Telecomunicaciones ASA.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p class="text-center wow fadeInDown">
            Entre otros tantos no menos importantes.{" "}
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Proyectos
